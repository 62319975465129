<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Nội dung<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="content"
              rules="required"
              :custom-messages="content"
            >
              <b-form-textarea
                v-model="dataInput.content"

                :disabled="type ==='edit'? true : false"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="File đính kèm"
            label-for="date"
            :class="type ==='add'? '':'d-none'"
          >
            <div class="d-flex">
              <b-form-input
                v-model="dataInput.fileName"
                :disabled="true"
              />

              <input
                ref="upload-file"
                type="file"
                class="d-none"
                @change="importFileExcel"
              />
              <b-button
                variant="outline-primary"
                class="btn-button-file ml-1"
                @click="uploadFile"
              >
                <feather-icon
                  icon="UploadIcon"
                  class="text-primary"
                />
              </b-button>
            </div>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      content: {
        required: 'Nội dung là bắt buộc',
      },
      dataInput: {
        content: '',
        modelFormData: '',
        fileName: '',
      },

    }
  },
  watch: {
    dataDetail() {
      if (this.type === 'edit') {
        this.dataInput = {
          content: this.dataDetail.content,
          modelFormData: this.dataDetail.modelFormData,
          fileName: this.dataDetail.fileName,
        }
      }
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        content: '',
        modelFormData: '',
        fileName: '',
      }
    },
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
